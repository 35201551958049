import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/web-client/app/_providers/emotion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/web-client/app/_providers/react-query.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/web-client/app/_providers/snackbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/web-client/app/_providers/theme.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/web-client/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Favicon"] */ "/app/apps/web-client/utils/favicon.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next-axiom@1.3.0_@types+node@20.9.3_@vitest+ui@1.5.0_vitest@1.5.0__jsdom@24.0.0_canvas@2.11.2_c3cum3dnjt2rz6hwqthxpv6r5m/node_modules/next-axiom/dist/webVitals/components.js");
